<template>
  <div class="hotRankingPage">
    <HeadBar titleName="热门排行榜"></HeadBar>
    <van-tabs
      class="navTabs oepnX"
      type="card"
      swipeable
      animated
      line-width="24"
      line-height="6"
      v-model="activeName"
      background="rgba(0,0,0,0)"
      title-active-color="#333333"
      title-inactive-color="rgba(153, 153, 153, 1)"
      v-if="navList && navList.length"
      @change="clickTag"
    >
      <van-tab v-for="(item, index) in navList" :key="index">
        <template #title>
          <span
            class="navTabTitle"
            :class="{ navTabTitleActive: activeName == index }"
          >
            {{ item.name }}
          </span>
        </template>
        <div class="hotRankingContent overflow-y-auto">
          <PullRefresh
            v-if="!isLoading"
            :loading="loading"
            :refreshing="refreshing"
            :finished="finished"
            @onLoad="onLoad"
            @onRefresh="onRefresh"
            :isNoData="isNoData"
            :error="error"
            :adv-list="advList"
            :hasAdvPagination="true"
            className="HotRankingPage"
          >
            <div class="mirrorContent" v-if="activeName == 2">
              <div
                class="contentItemDiaphragm"
                v-for="(item, index) in videoList"
                :key="index"
                @click="openDetails(videoList, item, index)"
              >
                <div
                  class="videoImg"
                  :class="{
                    videoImgDiaphragm: activeName == 2 || activeName == 3,
                  }"
                >
                  <ImgDecypt :src="item['horizontalCover']" />
                  <div class="mask_h">
                   <div class="maskText" v-if="item.updateStatus == 2">已完结·{{ item.totalEpisode }}话</div>
                   <div class="maskText" v-else>更新中·{{ item.totalEpisode }}话</div>
                  </div>
                </div>
                <div class="videoInfo">
                  <div class="videoTitle">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="videoTag" v-if="item['tagNames']">
                    <span
                      v-for="(tagItem, tagIndex) in item['tagNames'].slice(0, 3)"
                      :key="tagIndex"
                      class="videoTxtTag"
                      @click.stop="tagJump(item, tagIndex, tagItem)"
                    >
                      {{ tagItem }}
                    </span>
                  </div>
                  <!-- <div class="videoHot">
                    <img src="@/assets/png/hotFire.png" />
                    <span>{{ sumSli[index] }}</span>
                  </div> -->
                </div>
                <div
                  class="rankNum"
                  :class="
                    index == 0
                      ? 'rankNum_1'
                      : index == 1
                      ? 'rankNum_2'
                      : index == 2
                      ? 'rankNum_3'
                      : ''
                  "
                >
                 <span>{{ index > 2 ? (index + 1).toString().padStart(2, '0') : '' }}</span>
                </div>
                <!-- <div class="videoRanking">
                  <img src="@/assets/png/rankingOne.png" v-if="index == 0" />
                  <img src="@/assets/png/rankingTwo.png" v-if="index == 1" />
                  <img src="@/assets/png/rankingThree.png" v-if="index == 2" />
                  <span v-if="index > 2 && index < 9"> 0{{ index + 1 }} </span>
                  <span v-if="index > 8">
                    {{ index + 1 }}
                  </span>
                </div> -->
              </div>
            </div>
            <div class="waterfallItem" v-else-if="activeName === 3 || activeName === 0">
             <div class="waterfall">
              <div
                class="photoBox"
                v-for="(item, index) in videoList"
                :key="item.id"
                :style="computedRatio(item)"
                @click="openDetails(videoList, item, index)"
              >
               <div class="coverBox" v-if="item.verticalCover || item.horizontalCover">
                <ImgDecypt
                  class="cover"
                  :src="
              item.direction == 1 ? item.verticalCover : item.horizontalCover
            "
                  :key="
              item.direction == 1 ? item.verticalCover : item.horizontalCover
            "
                  :style="{ height: computedCoverHeight(item) + 'px' }"
                />
                <div class="label" :class="handelLabel(item.permission)"></div>
                <div class="statusBox" v-if="item.kind != 5">
                 {{ item.updateStatus == 2 ? "已完结·" : "更新中·"
                 }}{{ item.totalEpisode }}话
                </div>
                <div
                  class="rankNum"
                  :class="
                    index == 0
                      ? 'rankNum_1'
                      : index == 1
                      ? 'rankNum_2'
                      : index == 2
                      ? 'rankNum_3'
                      : ''
                  "
                >
                 <span>{{ index > 2 ? (index + 1).toString().padStart(2, '0') : '' }}</span>
                </div>
               </div>
               <div class="videoInfoBox">
                <div class="videoTitle">{{ item.title }}</div>
                <div class="dataBox" v-if="item.kind === 5">
                 <div class="countBox">
                  <SvgIcon iconClass="newPlayIcon" class="dataIcon"></SvgIcon>
                  {{ item.countBrowse | watchCount }}
                 </div>
                 <div class="countBox">
                  <SvgIcon iconClass="collect" class="dataIcon"></SvgIcon>
                  {{ item.countCollect | watchCount }}
                 </div>
                </div>
                <div v-else-if="!item.adv && item['tagNames']">
                 <span
                   v-for="(itemTag, indexTag) in item['tagNames'].slice(0, 3)"
                   :key="indexTag"
                   class="videoTxtTag"
                   :class="item.activeTagName === itemTag ? 'activeTag' : ''"
                   @click.stop="tagJump(item, indexTag, itemTag)"
                 >
                   <span>
                     {{ itemTag }}
                   </span>
                   <br v-if="(indexTag + 1) % 3 == 0" />
                 </span>
                </div>
               </div>
              </div>
             </div>
            </div>
            <div class="OtherContent" v-else>
              <div
                class="contentItem"
                v-for="(item, index) in videoList"
                :key="index"
                @click="openDetails(videoList, item, index)"
              >
                <div
                  class="videoImg"
                  :class="{
                    videoImgDiaphragm: activeName == 2 || activeName == 3,
                  }"
                >
                  <ImgDecypt :src="item['verticalCover'] || item['horizontalCover']" />
                  <div class="typeImg" v-if="item.kind === 7">
                   <img class="icon" src="@/assets/png/audio.png" alt="">
                  </div>
                  <div class="mask_v">
<!--                    <div-->
<!--                      class="maskText"-->
<!--                      :class="{ updateEnd: item.updateStatus == 2 }"-->
<!--                    >-->
<!--                      {{ item.updatedDesc }}-->
<!--                    </div>-->
                    <div class="maskText" v-if="item.updateStatus == 2">已完结·{{ item.totalEpisode }}话</div>
                    <div class="maskText" v-else>更新中·{{ item.totalEpisode }}话</div>
                  </div>
                  <div class="label" v-if="item.permission == 1">
                   <img class="icon" src="@/assets/png/goldLabelIcon.png" alt="">
                  </div>
                  <div class="label" v-if="item.permission == 2">
                   <img class="icon" src="@/assets/png/freeLabelIcon.png" alt="">
                  </div>
                  <div class="label" v-if="item.permission === 0">
                   <img class="icon" src="@/assets/png/vipLabelIcon.png" alt="">
                  </div>
                </div>
                <div class="videoInfo">
                  <div class="videoTitle">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="videoTag" v-if="item['tagNames']">
                    <span
                      v-for="(tagItem, tagIndex) in item['tagNames'].slice(0, 3)"
                      :key="tagIndex"
                      class="videoTxtTag"
                      @click.stop="tagJump(item, tagIndex, tagItem)"
                    >
                      {{ tagItem }}
                    </span>
                  </div>
                  <!-- <div class="videoHot">
                    <img src="@/assets/png/hotFire.png" />
                    <span>{{ sumSli[index] }}</span>
                  </div> -->
                </div>
                <div
                  class="rankNum"
                  :class="
                    index == 0
                      ? 'rankNum_1'
                      : index == 1
                      ? 'rankNum_2'
                      : index == 2
                      ? 'rankNum_3'
                      : ''
                  "
                >
<!--                 {{ index > 2 ? index + 1 : "" }}-->
                 <span>
                  {{ index > 2 ? (index + 1).toString().padStart(2, '0') : '' }}
                 </span>
                </div>

                <!-- <div class="videoRanking">
                  <img src="@/assets/png/rankingOne.png" v-if="index == 0" />
                  <img src="@/assets/png/rankingTwo.png" v-if="index == 1" />
                  <img src="@/assets/png/rankingThree.png" v-if="index == 2" />
                  <img src="@/assets/png/rankingFour.png" v-if="index == 3" />
                  <span v-if="index > 3 && index < 9">
                    <div class="">
                      <img src="@/assets/png/rankingFive.png" alt="">
                      {{ index + 1 }}
                    </div>
                  </span>
                  <span v-if="index > 9">
                    {{ index + 1 }}
                  </span>
                </div> -->
              </div>
            </div>
          </PullRefresh>
          <Loading v-else />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { queryLeaderboardList } from "@/api/video";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import ImgDecypt from "@/components/ImgDecypt";
import HeadBar from "@/components/HeadBar";
import SvgIcon from "@/components/SvgIcon";
import store from "@/store";
import {queryHistoryId} from "@/utils";
import {openDetails} from "@/utils/getConfig";
import Waterfall from "@/components/Waterfall/index.vue";
export default {
  name: "HotRankingPage",
  components: {
    PullRefresh,
    Loading,
    ImgDecypt,
    HeadBar,
   SvgIcon
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 12,
      activeName: 0,
      topicId: "",
      zone: "",
      titleName: "",
      videoList: [],
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      advList: [],
      error: false,
      navList: [],
      sumSli: [], //排行榜
      isAcg: true,
    };
  },
  computed: {
   computedRatio() {
    let height = 12;
    return (item) => {
     return {
      gridRowEnd: `span ${Math.floor(
        this.computedCoverHeight(item) / 4.9 + height
      )}`,
     };
    };
   },
  },
  mounted() {
    this.$nextTick(() => {
     this.getLeaderboardListInfo('refresh', 0);
    })
  },
  methods: {
   openDetails,
    computedCoverHeight(item) {
     if (item.direction == 1) {
      return 250;
     } else {
      return 94;
     }
    },
    handelLabel(permission) {
     if (permission == 1) {
      return "goldLabelIcon";
     } else if (permission == 2) {
      return "freeLabelIcon";
     } else if (permission == 0) {
      return "vipLabelIcon";
     }
    },
    async getLeaderboardListInfo(type, kind) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        kind: kind,
      };
      let res = await this.$Api(queryLeaderboardList, req);
      console.log(this.activeName, res);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      try {
        if (res && res.code == 200) {
          this.navList = res.data.rankInfo;
          // if (res.data.sumSli) {
          //   this.sumSli = res.data.sumSli;
          // }
          if (type == "refresh") {
            this.videoList = res.data.mediaList;
          } else {
            this.videoList = this.videoList.concat(res.data.mediaList);
          }

          if (
            this.pageNumber === 1 &&
            this.videoList &&
            this.videoList.length === 0
          ) {
            this.isNoData = true;
          }
          if (!res.data.hasNext || res.data.mediaList.length === 0) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        // this.loading = false;
      }
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      let kind = this.navList[this.activeName].kind;
      this.getLeaderboardListInfo("refresh", kind);
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      let kind = this.navList[this.activeName].Kind || 0;
      this.getLeaderboardListInfo(0, kind);
    },
    clickTag(index) {
      if (index != null) {
        this.activeName = index;
      }
      console.log(index);
      let kind = this.navList[index].kind || 0;
      // this.pageNumber = 1;
      this.finished = false;
      this.loading = true;
      this.isLoading = true;
      // this.isNoData = false;
      this.getLeaderboardListInfo("refresh", kind);
    },
    tagJump(item, index, itemTag) {
      let tagInfo = {
        id: item.tags[index],
        name: itemTag,
      };
      // console.log(this.$route.path)
      if (this.$route.path == "/tagList") {
        this.$router.replace({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
            t: Date.now(),
          },
        });
      } else {
        this.$router.push({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";
.hotRankingPage {
  height: 100%;
  /deep/ .headBar {
    .headTitle {
      font-size: 16px;
      color: #333333;
    }
  }
  .navTabs {
   padding-top: 20px;
   /deep/ .van-tabs__wrap {
     padding: 0 16px;
   }
    @include cardTab();

    .navTabTitle {
      font-size: 14px;
      color: #999999;
      font-weight: 900;
    }

    .navTabTitleActive {
      font-weight: 900;
      color: #333333;
    }

    ///deep/ .van-tabs__nav--complete {
    //    padding-left: 12px;
    //}
    //
    ///deep/ .van-tabs__wrap--scrollable .van-tab{
    //    padding: 0;
    //    padding-right:21px;
    //}
    //
    ///deep/ .van-tabs__wrap {
    //  height: 44px;
    //  margin-bottom: 12px;
    //  display: flex;
    //  justify-content: center;
    //}
    //
    ///deep/ .van-tabs__line {
    //  width: 18px !important;
    //  height: 2px !important;
    //  border-radius: 1px !important;
    //  background: #94d6da !important;
    //  bottom: 18px !important;
    //}
    //
    ///deep/ .van-tab {
    //  padding: 0;
    //  width: 40px;
    //  margin-right: 18px;
    //}

    .hotRankingContent {
      height: calc(100vh - 48px - 12px - 44px);
      padding: 0 10px;

      .mirrorContent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }
      .contentItemDiaphragm {
        width: 173px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        margin-bottom: 6px;
        position: relative;
        color: #000000;
        background: #fff;
        border-radius: 8px;

        .videoImg {
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 94px;
          border-radius: 8px;
          background: rgb(240, 240, 244);
          // border-radius: 6px;

          // /deep/ .van-image {
          //     border-radius: 6px;
          // }

          // /deep/ .van-image__img {
          //     border-radius: 6px;
          // }
        }

        .videoInfo {
          color: rgb(0, 0, 0);
          // width: calc(100% - 161px - 30.6px);
          width: 100%;
          padding: 5px 5px 10px 5px;

         .videoTitle {
           white-space: nowrap;
           overflow: hidden;
           text-overflow: ellipsis;
           color: #000;
           font-size: 12px;
           font-weight: 600;

            //overflow: hidden;
            //text-overflow: ellipsis;
            //display: -webkit-box;
            //-webkit-box-orient: vertical;
            //-webkit-line-clamp: 1;
          }

          .videoTag {
           display: flex;
           flex-wrap: wrap;
           font-size: 10px;
           color: #999;
           margin-top: 5px;

           span {
            margin-right: 6px;
           }
          }

          .videoHot {
            font-size: 12px;
            color: rgb(98, 98, 98);
            display: flex;
            align-items: center;

            img {
              width: 14.8px;
              height: 17px;
              margin-right: 12px;
            }
          }
        }

        // .videoRanking {
        //   width: 30.6px;
        //   font-size: 27px;
        //   color: rgb(142, 142, 144);

        //   img {
        //     width: 30.6px;
        //     height: 30px;
        //   }
        // }
      }
        .rankNum {
          width: 20px;
          height: 26px;
          //background-image: url("~@/assets/png/rankingFive.png");
          background-image: url("~@/assets/png/Angle.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: rgb(255, 255, 255);
          font-weight: bolder;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 9;
         span {
          position: absolute;
          top: 1px;
          right: 0;
          z-index: 1;
         }
        }
        .rankNum::after {
         content: '';
         background-image: url("~@/assets/png/page.png");
         background-repeat: no-repeat;
         background-size: 100% 100%;
         width: 20px;
         height: 26px;
         position: absolute;
         right: 1px;
         top: 0;
         z-index: 1;
        }

        .rankNum_1 {
          width: 22px;
          height: 22px;
          background: transparent;
          background-image: url("~@/assets/png/rankingOne.png") !important;
          background-size: 100% 100%;
        }
        .rankNum_1::after, .rankNum_2::after, .rankNum_3::after {
         content: '';
         background: transparent;
        }

        .rankNum_2 {
          width: 22px;
          height: 22px;
          background: transparent;
          background-image: url("~@/assets/png/rankingTwo.png");
          background-size: 100% 100%;
        }

        .rankNum_3 {
          width: 22px;
          height: 22px;
          background: transparent;
          background-image: url("~@/assets/png/rankingThree.png");
          background-size: 100% 100%;
        }
        //.rankNum_4 {
        //  width: 24px;
        //  height: 30px;
        //  background: transparent;
        //  background-image: url("~@/assets/png/rankingFour.png");
        //  background-size: 100% 100%;
        //}
     .waterfallItem {
      .waterfall {
       display: grid;
       grid-auto-rows: 5px;
       grid-template-columns: repeat(auto-fill, 174px);
       align-items: start;
       justify-content: space-between;

       .photoBox {
        width: 174px;
        display: flex;
        flex-wrap: wrap;
        background: #ffffff;
        border-radius: 8px;
        .coverBox {
         position: relative;
         .label {
          position: absolute;
          top: 0;
          left: 0;
          width: 32px;
          height: 20px;
         }
         .goldLabelIcon {
          background: url("../../assets/png/goldLabelIcon.png");
          background-size: 100% 100%;
         }
         .freeLabelIcon {
          background: url("../../assets/png/freeLabelIcon.png");
          background-size: 100% 100%;
         }
         .vipLabelIcon {
          background: url("../../assets/png/vipLabelIcon.png");
          background-size: 100% 100%;
         }
         .statusBox {
          position: absolute;
          right: 5px;
          bottom: 5px;
          padding: 0 9px;
          box-sizing: border-box;
          height: 20px;
          border-radius: 20px;
          background: rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(1.0499999523162842px);
          font-family: "PingFang SC";
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          color: #3e169d;
          display: flex;
          align-items: center;
          justify-content: center;
         }
        }
        .videoInfoBox {
         padding: 4px 5px 10px;
         box-sizing: border-box;
         .videoTitle {
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          width: 163px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
         }
         .dataBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 4px;
          font-family: "PingFang SC";
          font-size: 10px;
          .countBox {
           display: flex;
           align-items: center;
           justify-content: center;
          }
          .dataIcon {
           width: 16px;
           height: 16px;
           margin-right: 4px;
          }
         }
         .videoTxtTag {
          margin-top: 4px;
          font-size: 10px;
          font-weight: 400;
          color: #999;
          margin-right: 6px;
         }
        }

        .cover {
         width: 173px;
         border-radius: 8px;
         overflow: hidden;
        }

        .titleBox {
         width: 174px;

         .title {
          padding-top: 4px;
          width: 174px;
          color: #000000;
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          word-wrap: break-word;

          span {
           padding-left: 5px;
          }
         }

         .info {
          align-items: center;
          padding: 0 5px 7px;

          .tags {
           display: flex;
           flex-wrap: wrap;
           font-size: 10px;
           color: #999;
           margin-top: 5px;

           .tag {
            margin-right: 6px;
           }
          }

          span {
           font-size: 10px;
           color: #777777;
           margin-left: 2px;
          }
         }
        }
       }
      }
     }

      .OtherContent {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
      }
      .contentItem {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        position: relative;
        width: 113px;
        margin-bottom: 12px;
        color: #000000;
        background: #fff;
        border-radius: 8px;

        .videoImg {
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 166px;
          border-radius: 8px;
          background: rgb(240, 240, 244);
          // border-radius: 6px;

          // /deep/ .van-image {
          //     border-radius: 6px;
          // }

          // /deep/ .van-image__img {
          //     border-radius: 6px;
          // }
        }

        .videoInfo {
          // width: calc(100% - 90px - 30.6px);
          width: 100%;
          padding: 5px 5px 10px 5px;

         .videoTitle {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #000;
            font-size: 12px;
            font-weight: 600;

            //overflow: hidden;
            //text-overflow: ellipsis;
            //display: -webkit-box;
            //-webkit-box-orient: vertical;
            //-webkit-line-clamp: 1;
          }

          .videoTag {
           display: flex;
           flex-wrap: wrap;
           font-size: 10px;
           color: #999;
           margin-top: 5px;

           span {
            margin-right: 6px;
           }
          }

          .videoHot {
            font-size: 12px;
            color: rgb(98, 98, 98);
            display: flex;
            align-items: center;

            img {
              width: 14.8px;
              height: 17px;
              margin-right: 12px;
            }
          }
        }

        .videoRanking {
          width: 30.6px;
          font-size: 27px;
          color: rgb(142, 142, 144);

          img {
            width: 30.6px;
            height: 30px;
          }
        }
      }
    }
  }

  .content {
    //width: 100%;
    height: calc(100% - 44px);
    padding: 0 12px;
  }

  .mask_h {
   position: absolute;
   right: 5px;
   bottom: 5px;
   z-index: 2;
   display: flex;
   align-items: flex-end;
   justify-content: flex-end;
   padding: 0 9px;
   height: 20px;
   text-align: center;
   border-radius: 11px;
   background: rgba(255, 255, 255, 0.7);
   .maskText {
    font-size: 12px;
    font-weight: 500;
    color: #3E169D;
    line-height: 20px;
   }
   .updateEnd {
    color: #3E169D;
   }
  }
  .label {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 1;
   .icon{
    width: 32px;
    height: 20px;
   }
  }
  .typeImg {
   position: absolute;
   bottom: 6px;
   left: 6px;
   z-index: 1;
   width: 16px;
   height: 16px;
   background: rgba(0, 0, 0, 0.3);
   border-radius: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   img {
    width: 13px;
    height: 13px;
   }
  }
  .mask_v {
    position: absolute;
    right: 5px;
    bottom: 5px;
    z-index: 2;
    //width: 100%;
    //height: 81px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 9px;
    height: 20px;
    text-align: center;
    border-radius: 11px;
    background: rgba(255, 255, 255, 0.7);
    .maskText {
      font-size: 12px;
      font-weight: 500;
      color: #3E169D;
      line-height: 20px;
    }
    .updateEnd {
      color: #3E169D;
    }
  }
}
</style>
