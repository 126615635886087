<template>
    <div class="headBar">
        <div class="leftIcon" @click="$router.go(-1)">
            <!-- <img src="@/assets/png/left.png"> -->
            <img v-if="patternType === 1" class="navArrow" src="@/assets/png/leftBackIcon.png"  alt="">
            <svg-icon v-else class="navArrow" iconClass="navArrow" />
        </div>
        <div class="headTitle" :style="{'justify-content':titleLocation}">
            <span>{{ titleName }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeadBar",
    props:{
      titleName:{
          type: String,
          default: ""
      },
        titleLocation:{
          type: String, 
            default: "center"
        },patternType:{
          type:Number
        }
    }
}
</script>

<style lang="scss" scoped>
    .headBar{
        //border: solid 1px red;
        background: #FFFFFF;
        display: flex;
        height: 44px;
        align-items: center;
        // border-bottom: solid 1px rgb(43,39,74);

        .leftIcon{
            padding: 0 12px;
            .navArrow{
                width: 17px;
                height: 17px;
            }
        }

        .headTitle{
            color: #000000;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            //justify-content: center;
            margin-right: 24px;
            font-weight: 500;
            font-size: 16px;
        }
    }
</style>