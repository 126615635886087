var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hotRankingPage"},[_c('HeadBar',{attrs:{"titleName":"热门排行榜"}}),(_vm.navList && _vm.navList.length)?_c('van-tabs',{staticClass:"navTabs oepnX",attrs:{"type":"card","swipeable":"","animated":"","line-width":"24","line-height":"6","background":"rgba(0,0,0,0)","title-active-color":"#333333","title-inactive-color":"rgba(153, 153, 153, 1)"},on:{"change":_vm.clickTag},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.navList),function(item,index){return _c('van-tab',{key:index,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"navTabTitle",class:{ navTabTitleActive: _vm.activeName == index }},[_vm._v(" "+_vm._s(item.name)+" ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"hotRankingContent overflow-y-auto"},[(!_vm.isLoading)?_c('PullRefresh',{attrs:{"loading":_vm.loading,"refreshing":_vm.refreshing,"finished":_vm.finished,"isNoData":_vm.isNoData,"error":_vm.error,"adv-list":_vm.advList,"hasAdvPagination":true,"className":"HotRankingPage"},on:{"onLoad":_vm.onLoad,"onRefresh":_vm.onRefresh}},[(_vm.activeName == 2)?_c('div',{staticClass:"mirrorContent"},_vm._l((_vm.videoList),function(item,index){return _c('div',{key:index,staticClass:"contentItemDiaphragm",on:{"click":function($event){return _vm.openDetails(_vm.videoList, item, index)}}},[_c('div',{staticClass:"videoImg",class:{
                    videoImgDiaphragm: _vm.activeName == 2 || _vm.activeName == 3,
                  }},[_c('ImgDecypt',{attrs:{"src":item['horizontalCover']}}),_c('div',{staticClass:"mask_h"},[(item.updateStatus == 2)?_c('div',{staticClass:"maskText"},[_vm._v("已完结·"+_vm._s(item.totalEpisode)+"话")]):_c('div',{staticClass:"maskText"},[_vm._v("更新中·"+_vm._s(item.totalEpisode)+"话")])])],1),_c('div',{staticClass:"videoInfo"},[_c('div',{staticClass:"videoTitle"},[_c('span',[_vm._v(_vm._s(item.title))])]),(item['tagNames'])?_c('div',{staticClass:"videoTag"},_vm._l((item['tagNames'].slice(0, 3)),function(tagItem,tagIndex){return _c('span',{key:tagIndex,staticClass:"videoTxtTag",on:{"click":function($event){$event.stopPropagation();return _vm.tagJump(item, tagIndex, tagItem)}}},[_vm._v(" "+_vm._s(tagItem)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"rankNum",class:index == 0
                      ? 'rankNum_1'
                      : index == 1
                      ? 'rankNum_2'
                      : index == 2
                      ? 'rankNum_3'
                      : ''},[_c('span',[_vm._v(_vm._s(index > 2 ? (index + 1).toString().padStart(2, '0') : ''))])])])}),0):(_vm.activeName === 3 || _vm.activeName === 0)?_c('div',{staticClass:"waterfallItem"},[_c('div',{staticClass:"waterfall"},_vm._l((_vm.videoList),function(item,index){return _c('div',{key:item.id,staticClass:"photoBox",style:(_vm.computedRatio(item)),on:{"click":function($event){return _vm.openDetails(_vm.videoList, item, index)}}},[(item.verticalCover || item.horizontalCover)?_c('div',{staticClass:"coverBox"},[_c('ImgDecypt',{key:item.direction == 1 ? item.verticalCover : item.horizontalCover,staticClass:"cover",style:({ height: _vm.computedCoverHeight(item) + 'px' }),attrs:{"src":item.direction == 1 ? item.verticalCover : item.horizontalCover}}),_c('div',{staticClass:"label",class:_vm.handelLabel(item.permission)}),(item.kind != 5)?_c('div',{staticClass:"statusBox"},[_vm._v(" "+_vm._s(item.updateStatus == 2 ? "已完结·" : "更新中·")+_vm._s(item.totalEpisode)+"话 ")]):_vm._e(),_c('div',{staticClass:"rankNum",class:index == 0
                      ? 'rankNum_1'
                      : index == 1
                      ? 'rankNum_2'
                      : index == 2
                      ? 'rankNum_3'
                      : ''},[_c('span',[_vm._v(_vm._s(index > 2 ? (index + 1).toString().padStart(2, '0') : ''))])])],1):_vm._e(),_c('div',{staticClass:"videoInfoBox"},[_c('div',{staticClass:"videoTitle"},[_vm._v(_vm._s(item.title))]),(item.kind === 5)?_c('div',{staticClass:"dataBox"},[_c('div',{staticClass:"countBox"},[_c('SvgIcon',{staticClass:"dataIcon",attrs:{"iconClass":"newPlayIcon"}}),_vm._v(" "+_vm._s(_vm._f("watchCount")(item.countBrowse))+" ")],1),_c('div',{staticClass:"countBox"},[_c('SvgIcon',{staticClass:"dataIcon",attrs:{"iconClass":"collect"}}),_vm._v(" "+_vm._s(_vm._f("watchCount")(item.countCollect))+" ")],1)]):(!item.adv && item['tagNames'])?_c('div',_vm._l((item['tagNames'].slice(0, 3)),function(itemTag,indexTag){return _c('span',{key:indexTag,staticClass:"videoTxtTag",class:item.activeTagName === itemTag ? 'activeTag' : '',on:{"click":function($event){$event.stopPropagation();return _vm.tagJump(item, indexTag, itemTag)}}},[_c('span',[_vm._v(" "+_vm._s(itemTag)+" ")]),((indexTag + 1) % 3 == 0)?_c('br'):_vm._e()])}),0):_vm._e()])])}),0)]):_c('div',{staticClass:"OtherContent"},_vm._l((_vm.videoList),function(item,index){return _c('div',{key:index,staticClass:"contentItem",on:{"click":function($event){return _vm.openDetails(_vm.videoList, item, index)}}},[_c('div',{staticClass:"videoImg",class:{
                    videoImgDiaphragm: _vm.activeName == 2 || _vm.activeName == 3,
                  }},[_c('ImgDecypt',{attrs:{"src":item['verticalCover'] || item['horizontalCover']}}),(item.kind === 7)?_c('div',{staticClass:"typeImg"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/png/audio.png"),"alt":""}})]):_vm._e(),_c('div',{staticClass:"mask_v"},[(item.updateStatus == 2)?_c('div',{staticClass:"maskText"},[_vm._v("已完结·"+_vm._s(item.totalEpisode)+"话")]):_c('div',{staticClass:"maskText"},[_vm._v("更新中·"+_vm._s(item.totalEpisode)+"话")])]),(item.permission == 1)?_c('div',{staticClass:"label"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/png/goldLabelIcon.png"),"alt":""}})]):_vm._e(),(item.permission == 2)?_c('div',{staticClass:"label"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/png/freeLabelIcon.png"),"alt":""}})]):_vm._e(),(item.permission === 0)?_c('div',{staticClass:"label"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/png/vipLabelIcon.png"),"alt":""}})]):_vm._e()],1),_c('div',{staticClass:"videoInfo"},[_c('div',{staticClass:"videoTitle"},[_c('span',[_vm._v(_vm._s(item.title))])]),(item['tagNames'])?_c('div',{staticClass:"videoTag"},_vm._l((item['tagNames'].slice(0, 3)),function(tagItem,tagIndex){return _c('span',{key:tagIndex,staticClass:"videoTxtTag",on:{"click":function($event){$event.stopPropagation();return _vm.tagJump(item, tagIndex, tagItem)}}},[_vm._v(" "+_vm._s(tagItem)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"rankNum",class:index == 0
                      ? 'rankNum_1'
                      : index == 1
                      ? 'rankNum_2'
                      : index == 2
                      ? 'rankNum_3'
                      : ''},[_c('span',[_vm._v(" "+_vm._s(index > 2 ? (index + 1).toString().padStart(2, '0') : '')+" ")])])])}),0)]):_c('Loading')],1)])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }